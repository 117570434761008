.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background-color: #282c34;
  min-height: 100vh;
  padding: 30px;
}

.Admin {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Blog-post {
  width: 100%;
}
